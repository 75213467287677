import React from 'react';

import AboutSection from '../components/about-section';
import Layout from '../components/layout';
import HeroSection from '../components/hero-section';
import Carousels from '../components/carousels';
import Dynamic from '../components/dynamic';

export default () => (
  <Layout>
    <HeroSection cn="pnd">
      <h2>Prodaja naftnih derivata</h2>
    </HeroSection>
    <AboutSection>
      <p>
        Osnovna djelatnost firme Keso-Promet d.o.o. je maloprodaja nafte i
        naftnih derivata preko maloprodajne mreže benzinskih stanica koje se
        nalaze na 4 atraktivne lokacije na području Zvornika - Zvornik, Karakaj,
        Tršić i Caparde.
      </p>
      <p>
        Uz maloprodaju nafte i naftnih derivata, na našim benzinskim stanicama
        nudimo i veliki izbor motornih ulja i derivata renomiranih svjetskih
        proizvođača, auto opreme i pribora, te veliku ponudu mješovite robe
        široke potrošnje.
      </p>
    </AboutSection>
    <Carousels />
    <Dynamic path="stations-map" />
  </Layout>
);
