import React from 'react';

import Carousel from './carousel';
import { stations } from '../data/benzinske-pumpe.json';

export default () => (
  <section className="pb-0">
    <div className="container">
      {stations
        .filter(station => station.images)
        .map(station => (
          <div key={`pumpa-${station.name}`}>
            <Carousel
              name={station.name}
              location={station.location}
              time={station.work_time}
              images={station.images}
            />
          </div>
        ))}
    </div>
  </section>
);
